/*jslint browser: true*/
/*eslint-env browser*/
/*global jQuery*/

jQuery(document).ready(function ($) {
	'use strict';
	
	$(document).foundation();

	$(".single-galeria .gallery").lightGallery({
		selector: '.gallery-icon a'
	});
	
	$('#kiallitoi-logok .owl-carousel').owlCarousel(
		{
			/*items: 4,*/
			loop: true,
			autoplay: true,
			smartSpeed: 1200,
			autoplayHoverPause: true,
			nav: true,
			dots: false,
			navText: ["<i class='fa fa-angle-left' aria-hidden='true'></i>","<i class='fa fa-angle-right' aria-hidden='true'></i>"],
			responsive: {
				0: {
					items: 2,
					stagePadding: 25,
					margin: 30
				},
				640: {
					items: 4,
					stagePadding: 25,
					margin: 30
				},
				1024: {
					items: 6,
					stagePadding: 25,
					margin: 30
				}
			}
		}
	);

});